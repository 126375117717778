export const errorMessages = new Map([
  ['be_ad_amount_over_expected_err', 'Số tiền tạm ứng vượt mức cho phép'],
  ['be_ex_amount_over_expected_err', 'Số tiền thanh toán vượt mức cho phép'],
  ['be_invalid_po_usage_err', 'Bạn không có quyền sử dụng đơn hàng này, đơn hàng này thuộc về một nhân viên khác'],
  ['be_invalid_po_status_err', 'Đơn hàng này chưa được duyệt'],
  ['be_unauthorized_to_update_status', 'Bạn không có quyền thay đổi trạng thái của phiếu này'],
  ['be_validation_failed', 'Vui lòng điền thông tin bắt buộc'],
  ['be_purchase_not_found', 'Không tìm thấy đơn mua hàng được yêu cầu'],
  ['be_approver_not_found', 'Không tìm thấy người duyệt này trong hệ thống'],
  ['be_unauthorized_to_update', 'Bạn không có quyền thực hiện tác vụ cập nhật'],
  ['be_expense_not_found_err', 'Không tìm thấy đề nghị thanh toán này'],
  ['be_missing_po_id_err', 'Vui lòng chọn mã đơn hàng tương ứng'],
  ['be_missing_customer_info_err', 'Vui lòng cung cấp thông tin khách hàng'],
  ['be_missing_budget_code_info', 'Vui lòng cung cấp thông tin mã ngân sách'],
  ['be_missing_department', 'Vui lòng chọn bộ phận cung cấp'],
  ['be_target_missing', 'Đối tượng phân bổ không được để trống'],
  ['be_expense_cost_sharing_not_found', 'Không tìm thấy đối tượng phân bổ'],
  ['be_cost_application_invalid', 'Số tiền phân bổ vượt quá hoặc chưa đạt mức cho phép'],
  ['be_contract_not_found', 'Không tìm thấy hợp đồng tương ứng'],
  ['be_advance_detail_not_found', 'Không tìm thấy chi tiết đơn tạm ứng'],
  ['be_budget_item_not_found', 'Không tìm thấy số tài khoản'],
  ['be_ad_reject_error', 'Không thể từ chối tạm ứng khi chưa từ chối phiếu thanh toán'],
  ['be_role_not_set_err', 'Vui lòng thiết lập nhân viên làm người duyệt hoặc người kiểm tra'],
  ['be_invalid_role_config', 'Không thể  thiết lập nhân viên vừa làm người duyệt và người kiểm tra'],
  ['be_internal_server_error', 'internal_server_error'],
]);

export function getMessage(errorMessage: string) {
  return errorMessages.get(errorMessage) ? errorMessages.get(errorMessage) : 'Đã có lỗi xảy ra, vui lòng thử lại sau';
}
