import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { AppLanguageComponent } from './app-language.component';
import { NbSelectModule } from '@nebular/theme';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?dummy=' + Math.random().toString());
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NbSelectModule,
    TranslateModule,
    // .forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: createTranslateLoader,
    //     deps: [HttpClient]
    //   }
    // })
  ],
  declarations: [AppLanguageComponent],
  exports: [AppLanguageComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppLanguageModule {}
