export const TALENTNET_ROOT_ROUTE = 'app';

export const COMPONENT_ROUTE = {
  ORGANIZATION: 'organization',
  ORGANIZATION_PATH: {
    MEMBER: 'member',
  },
  SYSTEM_CONFIG: 'system-config',
  SYSTEM_CONFIG_PATH: {
    MASTER_DATA: 'master-data',
    BUDGET_CONFIG: 'budget-config',
    ROLE_CONFIG: 'role',
    EMPLOYEE: 'employee',
    APPROVER: 'approver',
    CONFIG_ACCOUNT: 'config-account',
    IMPORT_CSV: 'import-csv',
    EXPORT_CSV: 'export-csv',
    BIZZI_LOG: 'bizzi-monitor',
    INTEGRATION_LOG: 'log-monitor',
    EMAIL_SETTINGS: 'email-settings',
  },
  INTERNAL: 'internal',
  INTERNAL_PATH: {
    ORDER_MANAGEMENT: 'order-management',
    BUDGET_CONTROL: 'budget-control',
    BUDGET_CONTROL_DETAIL: 'budget-detail',
    BUDGET_CONTROL_NEW: 'budget-request-new',
    CREATE_ORDER: 'create-order',
    ORDER_DETAIL: 'order-detail',
    INTERNAL_REPORT: 'internal-report',
    INTERNAL_REPORT_PATH: {
      REPORT_AGGREGATED_BALANCE: 'aggregated-balance',
      REPORT_DETAIL_BALANCE: 'detail-balance',
      BUDGET_REQUEST_REPORT: 'budget-request',
      REPORT_PO_AGGREGATE: 'po-aggregate',
      REPORT_PO_BALANCE: 'po-balance',
    },
    ADVANCE: 'advance',
    CREATE_AD: 'create-ad',
    AD_DETAIL: 'ad-detail',

    EXPENSE: 'expense',
    CREATE_EXPENSE: 'create-expense',
    EXPENSE_DETAIL: 'expense-detail',

    CONTRACT: 'contract',
    CREATE_CONTRACT: 'create-contract',
    CONTRACT_DETAIL: 'contract-detail',

    BUDGET: 'budget',
  },

  OSS_MANAGEMENT: 'oss-management',
  OSS_MANAGEMENT_PATH: {
    ADVANCE: 'advance',
    CREATE_AD: 'create-ad',
    AD_DETAIL: 'ad-detail',
    ADVANCE_LIMIT: 'ad-limit',
    EXPENSE_ORIGINAL: 'expense',
    CREATE_EXPENSE_ORIGINAL: 'create-expense',
    EXPENSE_DETAIL_ORIGINAL: 'expense-detail',
    EXPENSE_SCAN_CONVERT: 'expense-convert',
    DOCUMENT_CATEGORY: 'document-category',
    LIMIT_REVISE: 'limit-revise',
    CREATE_LIMIT_REVISE: 'create-limit-revise',
    DETAIL_LIMIT_REVISE: 'detail-limit-revise',
    BANKTRANSFER: 'bank-transfer',
    REPORT_RECONCILE: 'reconcile',
    REPORT_OSS_EX_INFO: 'oss-ex-info-report',
    REPORT_OSS_ADVANCE_LIMIT: 'oss-advance-limit-report',
    REPORT_OSS_PAYMENT_LIMIT: 'oss-advance-payment-report',
    OSS_REPORT: 'oss-report',
    CREATE_BANK_TRANSFER: 'create-bank-transfer',
    BANK_TRANSFER_DETAIL: 'bank-transfer-detail',
  },

  INVOICE: 'invoice',
  INVOICE_PATH: {
    LIST_INVOICE: 'list',
    CREATE_INVOICE: 'new',
    REVENUE_REPORT: 'revenue-report',
    SHARE_REVENUE: 'share-revenue',
  },

  REVENUE_SHARING: 'revenue-sharing',

  ELEARNING: 'elearning',
  ELEARNING_PATH: {
    LIST: 'list',
  },

  SOA_EMAIL: 'soa-email',

  BIZZI: 'bizzi',
  EINVOICE: 'einvoice',
  ACTIVITY_LOG: 'activity-log',
};
export const ROUTE_CONTANTS = {
  HOME: '/app/dashboard',
  LANDING_PAGE: '/app/dashboard',
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  NOT_FOUND: '/app/not-found',
  CHANGE_PASSWORD: '/auth/login',
  ORGANIZATION: {
    MEMBER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.ORGANIZATION}/${COMPONENT_ROUTE.ORGANIZATION_PATH.MEMBER}`,
  },
  INTERNAL: {
    PURCHASE_ORDER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.ORDER_MANAGEMENT}`,
    CREATE_ORDER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.ORDER_MANAGEMENT}/${COMPONENT_ROUTE.INTERNAL_PATH.CREATE_ORDER}`,
    ORDER_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.ORDER_MANAGEMENT}/${COMPONENT_ROUTE.INTERNAL_PATH.ORDER_DETAIL}`,
    BUDGET: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.BUDGET}`,
    BUDGET_CONTROL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.BUDGET_CONTROL}`,
    BUDGET_CONTROL_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.BUDGET_CONTROL}/${COMPONENT_ROUTE.INTERNAL_PATH.BUDGET_CONTROL_DETAIL}`,
    BUDGET_CONTROL_NEW: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.BUDGET_CONTROL}/${COMPONENT_ROUTE.INTERNAL_PATH.BUDGET_CONTROL_NEW}`,
    REPORT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT}`,
    REPORT_AGGREGATED_BALANCE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT_PATH.REPORT_AGGREGATED_BALANCE}`,
    REPORT_DETAIL_BALANCE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT_PATH.REPORT_DETAIL_BALANCE}`,
    REPORT_BUDGET_REQUEST: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT_PATH.BUDGET_REQUEST_REPORT}`,
    REPORT_PO_AGGREGATE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT_PATH.REPORT_PO_AGGREGATE}`,
    REPORT_PO_BALANCE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT_PATH.REPORT_PO_BALANCE}`,
    ADVANCE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.ADVANCE}`,
    CREATE_AD: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.ADVANCE}/${COMPONENT_ROUTE.INTERNAL_PATH.CREATE_AD}`,
    AD_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.ADVANCE}/${COMPONENT_ROUTE.INTERNAL_PATH.AD_DETAIL}`,
    EXPENSE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.EXPENSE}`,
    CREATE_EXPENSE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.EXPENSE}/${COMPONENT_ROUTE.INTERNAL_PATH.CREATE_EXPENSE}`,
    EXPENSE_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.EXPENSE}/${COMPONENT_ROUTE.INTERNAL_PATH.EXPENSE_DETAIL}`,
    CONTRACT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.CONTRACT}`,
    CREATE_CONTRACT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.CONTRACT}/${COMPONENT_ROUTE.INTERNAL_PATH.CREATE_CONTRACT}`,
    CONTRACT_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.CONTRACT}/${COMPONENT_ROUTE.INTERNAL_PATH.CONTRACT_DETAIL}`,
    BANK_TRANSFER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANKTRANSFER}`,
    CREATE_BANK_TRANSFER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANKTRANSFER}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.CREATE_BANK_TRANSFER}`,
    BANK_TRANSFER_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANKTRANSFER}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANK_TRANSFER_DETAIL}`,
  },
  SYSTEM_CONFIG: {
    MASTER_DATA: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.MASTER_DATA}`,
    BUDGET_CONFIG: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.BUDGET_CONFIG}`,
    ROLE_CONFIG: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.ROLE_CONFIG}`,
    EMPLOYEE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.EMPLOYEE}`,
    APPROVER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.APPROVER}`,
    CONFIG_ACCOUNT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.CONFIG_ACCOUNT}`,
    IMPORT_CSV: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.IMPORT_CSV}`,
    EXPORT_CSV: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.EXPORT_CSV}`,
    BIZZI_LOG: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.BIZZI_LOG}`,
    INTEGRATION_LOG: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.INTEGRATION_LOG}`,
    EMAIL_SETTINGS: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.EMAIL_SETTINGS}`,
  },
  OSS_MANAGEMENT: {
    ADVANCE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.ADVANCE}`,
    ADVANCE_LIMIT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.ADVANCE_LIMIT}`,
    CREATE_AD: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.ADVANCE}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.CREATE_AD}`,
    AD_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.ADVANCE}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.AD_DETAIL}`,
    EXPENSE_ORIGINAL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.EXPENSE_ORIGINAL}`,
    EXPENSE_DETAIL_ORIGINAL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.EXPENSE_ORIGINAL}/:exType/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.EXPENSE_DETAIL_ORIGINAL}`,
    DOCUMENT_CATEGORY: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.DOCUMENT_CATEGORY}`,
    LIMIT_REVISE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.LIMIT_REVISE}`,
    CREATE_LIMIT_REVISE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.LIMIT_REVISE}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.CREATE_LIMIT_REVISE}`,
    DETAIL_LIMIT_REVISE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.LIMIT_REVISE}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.DETAIL_LIMIT_REVISE}`,
    BANK_TRANSFER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANKTRANSFER}`,
    CREATE_BANK_TRANSFER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANKTRANSFER}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.CREATE_BANK_TRANSFER}`,
    BANK_TRANSFER_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANKTRANSFER}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANK_TRANSFER_DETAIL}`,
    REPORT_RECONCILE_OSS: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.OSS_REPORT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.REPORT_RECONCILE}`,
    REPORT_OSS_EX_INFO: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.OSS_REPORT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.REPORT_OSS_EX_INFO}`,
    REPORT_OSS_ADVANCE_LIMIT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.OSS_REPORT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.REPORT_OSS_ADVANCE_LIMIT}`,
    REPORT_OSS_PAYMENT_LIMIT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.OSS_REPORT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.REPORT_OSS_PAYMENT_LIMIT}`,
  },
  INVOICE: {
    ROOT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INVOICE}`,
    INVOICE_LIST: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INVOICE}/${COMPONENT_ROUTE.INVOICE_PATH.LIST_INVOICE}`,
    CREATE_INVOICE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INVOICE}/${COMPONENT_ROUTE.INVOICE_PATH.CREATE_INVOICE}`,
    SHARE_REVENUE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INVOICE}/${COMPONENT_ROUTE.INVOICE_PATH.SHARE_REVENUE}`,
  },
  REVENUE_SHARING: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.REVENUE_SHARING}`,
  SOA_EMAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SOA_EMAIL}`,
  ELEARNING: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.ELEARNING}/${COMPONENT_ROUTE.ELEARNING_PATH.LIST}`,
  BIZZI: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.BIZZI}`,
  EINVOICE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.EINVOICE}`,
  ACTIVITY_LOG: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.ACTIVITY_LOG}`,
};
