export const environment = {
  production: true,
  test: false,
  apiBaseUrl: 'https://api.riosolution.net',

  defaultVersion: '/eclaim-service/v1/api',
  enableCaptcha: false,
  urlLoginAzure: 'https://sso.riosolution.net/api/org/saml/sso',
  appBaseUrl: 'https://eclaim.riosolution.net',
  apiSsoUrl: 'https://sso.riosolution.net',
};
