<!-- <nb-select [(selected)]="selectedLanguage" (selectedChange)="onLanguageSelect($event)">
    <nb-option value="vi"><img src="assets/images/vn.svg" style="width: auto; height: 20px;" /></nb-option>
    <nb-option value="us"><img src="assets/images/us.svg" style="width: auto; height: 20px;" /></nb-option>
</nb-select> -->

<ng-select class="app-lang-component" *ngIf="languageItems" [searchable]="false" [clearable]="false" [(ngModel)]="selectedLanguage" (change)="onLanguageSelect($event)">
  <ng-option *ngFor="let option of languageItems" [value]="option.value">
    <img [src]="option.svg" style="width: auto; height: 20px" />
  </ng-option>
</ng-select>
