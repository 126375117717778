import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

export function toNonAccentVietnamese(str) {
  str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ|Ả|Ẩ/g, 'A');
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ|Ể/, 'E');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/I|Í|Ì|Ĩ|Ị|Ỉ/g, 'I');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ổ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, 'O');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự|Ử|Ủ/g, 'U');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ|Ỷ/g, 'Y');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/Đ/g, 'D');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  str = str.replace(/\//g, '_'); // / => _
  str = str.replace(/\(/g, ''); // ( =>
  str = str.replace(/\)/g, ''); // ) =>
  str = str.replace(/\!/g, ''); // ! =>
  str = str.replace(/\?/g, ''); // ! =>
  // str = str.replace(/\,/g, '_'); // , => _
  str = str.replace(/\./g, ''); // , => _
  str = str.replace(/\,/g, ''); // ! =>
  str = str.replace(/\'/g, ''); // ! =>
  str = str.replace(/\`/g, ''); // ! =>
  str = str.replace(/\"/g, ''); // ! =>
  str = str.replace(/\&/g, ''); // ! =>
  str = str.replace(/\_$/, ''); // ! => last char
  return String(str).trim();
}

@Pipe({ name: 'textToTranslateKey' })
export class TextToTranslateKeyPipe implements PipeTransform {
  constructor() {}

  transform(value) {
    let upper = String(value).toUpperCase();
    let arrayText = String(upper)
      .split(' ')
      .map((t) => String(t).trim())
      .filter((t) => !!t)
      .map((t) => {
        return toNonAccentVietnamese(t);
      });
    return arrayText.filter((r) => !!r).join('_');
  }
}
