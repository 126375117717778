<nb-layout>
  <nb-layout-column style="padding: 0">
    <div class="login-page-callback">
      <div *ngIf="error" class="alert alert-danger alert-dismissible">
        <h3 class="text-danger">
          {{ 'Login Fail' | textToTranslateKeyAuth | translate }}
        </h3>
        <div class="" role="alert">
          <span class="text-danger">
            {{ error || ('There are some errors, please try again' | textToTranslateKeyAuth | translate) }}
          </span>
        </div>
        <div class="w-100 mt-5">
          <button class="btn btn-outline-primary" (click)="clickToLogin()">
            {{ 'Login Again' | textToTranslateKeyAuth | translate }}
          </button>
        </div>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
