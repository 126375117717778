import { EpoLoginService } from '../../../common/services/epo-login.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthSocialLink } from '../../auth.options';
import { getDeepFromObject, isLocalhost } from '../../helpers';

import { NbAuthService } from '../../services/auth.service';
import { NbAuthResult } from '../../services/auth-result';
import { NbGlobalLogicalPosition, NbToastrService } from '@nebular/theme';
import { ServiceHelper } from '../../../common/helpers/service.helper';
import { AppLanguageService } from '../../../common/services/app-language.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'nb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NbLoginComponent implements OnInit {
  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {
    username: isLocalhost() ? 'superadmin' : '',
    password: isLocalhost() ? 'eclaim@123' : '',
  };
  submitted: boolean = false;
  socialLinks: NbAuthSocialLink[] = [];
  rememberMe = false;
  loginFailed = false;
  labels: any = {};

  constructor(
    protected service: NbAuthService,
    protected epoLoginService: EpoLoginService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    private cd: ChangeDetectorRef,
    private appLanguageService: AppLanguageService,
    protected router: Router
  ) {
    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.strategy = this.getConfigValue('forms.login.strategy');
    this.socialLinks = this.getConfigValue('forms.login.socialLinks');
    this.rememberMe = this.getConfigValue('forms.login.rememberMe');
    this.appLanguageService.use(this.appLanguageService.getLanguage());
  }

  loadLabelsI18n() {
    this.labels = {
      // dontHaveAccount: this.appLanguageService.instant(),
    };
  }
  ngOnInit() {
    this.service.stopLogoutTimer();
  }

  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;
    this.loginFailed = false;
    if (ServiceHelper.isObjectEmpty(this.user) || ServiceHelper.isObjectEmpty(this.user.username) || ServiceHelper.isObjectEmpty(this.user.password)) {
      this.submitted = false;
      return;
    }
    this.user.username = this.user.username.trim();
    this.user.password = this.user.password.trim();
    if (ServiceHelper.isObjectEmpty(this.user.username) || ServiceHelper.isObjectEmpty(this.user.password)) {
      this.submitted = false;
      return;
    }

    this.service
      .authenticate(this.strategy, {
        ...this.user,
        user: this.user.username,
        pass: this.user.passport,
      })
      .subscribe(
        (result: NbAuthResult) => {
          let isIntegrationAccount = false;
          if (result.isSuccess()) {
            const roles: Array<string> = result.getResponse().body.data.listRole;
            if (roles.includes('Integration')) {
              this.loginFailed = true;
              isIntegrationAccount = true;
              this.cd.markForCheck();
            } else {
              this.messages = result.getMessages();
            }
          } else {
            this.loginFailed = true;
            this.errors = result.getErrors();
            this.submitted = false;
            this.cd.markForCheck();
          }

          const redirect = result.getRedirect();
          if (!isIntegrationAccount && redirect) {
            setTimeout(() => {
              return this.router.navigateByUrl(redirect);
            }, this.redirectDelay);
          }
        },
        (error) => {
          this.loginFailed = true;
          this.submitted = false;
          // console.error(error);
          // if(ServiceHelper.isAllowShowBEMessage(error)){
          //   let {message, key} = ServiceHelper.getApiErrorKeyMessage(error);
          //   console.error(`[Parse error] ${error?.message}`, error?.error, {message, key, status:  error?.status});
          //   ServiceHelper.errorPopup(message || this.appLanguageService.instantCustom(key || 'Lỗi từ hệ thống, vui lòng thử lại sau'));
          // }
        },
        () => {
          this.submitted = false;
        }
      );
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  clickLoginAzure() {
    let redirectUrl = `${environment.appBaseUrl}/auth/login/callback`;
    let url = `${environment.urlLoginAzure}?redirectUrl=${encodeURIComponent(redirectUrl)}`;
    console.log(`login azure go to url`, url);
    window.location.href = url;
  }
}
