import { API_URL } from './api.constants';
export const LANG_VI = 'vi';
export const LANG_EN = 'en';
export const PAGE_IDS = {
  EINVOICE: 'EINVOICE',
};

export const TYPE_BOOLEAN = 'boolean';
export const TYPE_CHECKBOX = 'boolean';
export const TYPE_STATUS = 'status';
export const TYPE_STRING = 'string';
export const TYPE_NUMBER = 'number';
export const TYPE_NUMBER_CUSTOM = 'numberCustom';
export const TYPE_DATE = 'date';
export const TYPE_SELECT_OPTIONS_HARD = 'selectOptionsHard';
export const TYPE_SELECT_OPTIONS_AJAX = 'selectOptionsAjax';
export const KEY_ALLOW_SORT_BE = 'allowSortAsync';
export const TYPE_STATUS_BADGE = 'statusBadge';
export const TYPE_DATE_RANGE = 'dateRange';
export const TYPE_DATE_PICKER_YEAR = 'pickerYear';
export const TYPE_AJAX_SELECT = 'ajaxSelect';
export const TYPE_SELECT = 'select';

export const ECL_SEARCH_FORM_TYPE_KEY = {
  TYPE_BOOLEAN: TYPE_BOOLEAN,
  TYPE_CHECKBOX: TYPE_CHECKBOX,
  TYPE_STATUS: TYPE_STATUS,
  TYPE_STRING: TYPE_STRING,
  TYPE_NUMBER: TYPE_NUMBER,
  TYPE_NUMBER_CUSTOM: TYPE_NUMBER_CUSTOM,
  TYPE_DATE: TYPE_DATE,
  TYPE_DATE_RANGE: TYPE_DATE_RANGE,
  TYPE_DATE_PICKER_YEAR: TYPE_DATE_PICKER_YEAR,
  TYPE_STATUS_BADGE: TYPE_STATUS_BADGE,
  TYPE_AJAX_SELECT: TYPE_AJAX_SELECT,
  TYPE_SELECT: TYPE_SELECT,
};

export const MASTER_DATA_TABLE_COLUMN_KEY = {
  TYPE_SELECT_OPTIONS_HARD: TYPE_SELECT_OPTIONS_HARD,
  TYPE_SELECT_OPTIONS_AJAX: TYPE_SELECT_OPTIONS_AJAX,
};

// const TYPE_BOOLEAN = 'boolean';
// const TYPE_CHECKBOX = 'boolean';
// const TYPE_STATUS = 'status';
// const TYPE_STATUS_BADGE = 'statusBadge';
// const TYPE_STRING = 'string';
// const TYPE_NUMBER = 'number';
// const TYPE_NUMBER_CUSTOM = 'numberCustom';
// const TYPE_DATE = 'date';
// const TYPE_AJAX_SELECT = 'ajaxSelect';
// const KEY_ALLOW_SORT_BE = 'allowSortAsync';
export const ECL_TABLE_COLUMN_TYPE_KEY = {
  TYPE_BOOLEAN: TYPE_BOOLEAN,
  TYPE_CHECKBOX: TYPE_CHECKBOX,
  TYPE_STATUS: TYPE_STATUS,
  TYPE_STRING: TYPE_STRING,
  TYPE_NUMBER: TYPE_NUMBER,
  TYPE_NUMBER_CUSTOM: TYPE_NUMBER_CUSTOM,
  TYPE_DATE: TYPE_DATE,
  TYPE_STATUS_BADGE: TYPE_STATUS_BADGE,
  TYPE_AJAX_SELECT: TYPE_AJAX_SELECT,
  // KEY_ALLOW_SORT_BE:KEY_ALLOW_SORT_BE
};
export const ECL_TABLE_SEARCH_TYPE_KEY = {
  TYPE_SLIDER: 'slider',
  TYPE_DATE_RANGE: 'dateRange',
};
export const ECL_TABLE_ALLOW_SORT_KEY = {
  KEY_ALLOW_SORT_BE: KEY_ALLOW_SORT_BE,
};

export const APP_CONSTANTS = {
  LANGUAGE: 'epo_language',
};

export const APPROVE_LEVEL = {
  LEVEL_A: 'A',
  LEVEL_B: 'B',
  CHARGEABLE: 'CH',
};

class EpoStatus {
  public static DRAFT = 'DRAFT';
  public static WAITING = 'WAITING';
  public static APPROVED = 'APPROVED';
  public static REJECTED = 'REJECTED';
  // public static CANCELED = 'CANCELED';
  public static CLOSED = 'CLOSED';
  public static RECORDED = 'RECORDED';
  public static HALF_DONE = 'HALF_DONE';
  public static cancelable = (status) => {
    return status === EpoStatus.DRAFT;
  };

  public static sendForApproveable = (status) => {
    if (status) {
      return status === EpoStatus.DRAFT || status === EpoStatus.REJECTED;
      // || status === EpoStatus.CANCELED;
    }
    return false;
  };

  public static isStatusEditable = (status) => {
    if (status) {
      return status === EpoStatus.DRAFT || status === EpoStatus.REJECTED;
    }
    return false;
  };

  public static statusFilterList(): string[] {
    return [
      'ALL',
      'DRAFT',
      'WAITING',
      'APPROVED',
      'REJECTED',
      // 'CANCELED',
    ];
  }

  public static invoiceStatusFilterList(): string[] {
    return ['ALL', 'DRAFT', 'WAITING', 'REJECTED', 'RECORDED'];
  }

  public static bizziFilterList(): string[] {
    return [
      'ALL',
      'DRAFT',
      'APPROVED',
      'REJECTED',
      // 'CANCELED',
    ];
  }
}

export const EPO_STATUS = EpoStatus;

export const BUDGET_REQUEST_TYPE = {
  ACCRUAL_TRANSFER: 'ACCRUAL_TRANSFER',
  HIGHER_BUDGET: 'HIGHER_BUDGET',
  COST_TRANSFER: 'COST_TRANSFER',
  ADVANCED_FROM_NEXT_YEAR: 'ADVANCED_FROM_NEXT_YEAR',
};

const budgetRequestTypeMap = {};
budgetRequestTypeMap[BUDGET_REQUEST_TYPE.ACCRUAL_TRANSFER] = 'Accrual Transfer';
budgetRequestTypeMap[BUDGET_REQUEST_TYPE.HIGHER_BUDGET] = 'Higher Budget';
budgetRequestTypeMap[BUDGET_REQUEST_TYPE.COST_TRANSFER] = 'Cost Transfer';
budgetRequestTypeMap[BUDGET_REQUEST_TYPE.ADVANCED_FROM_NEXT_YEAR] = "Advance from next year's budget";
export const BUDGET_REQUEST_TYPE_MAP = budgetRequestTypeMap;

const statusMap = {};
statusMap[EPO_STATUS.DRAFT] = 'Nháp';
statusMap[EPO_STATUS.WAITING] = 'Đang chờ xét duyệt';
statusMap[EPO_STATUS.APPROVED] = 'Đã được duyệt';
statusMap[EPO_STATUS.REJECTED] = 'Đã bị từ chối';
statusMap[EPO_STATUS.HALF_DONE] = 'Hoàn thành một nửa';
statusMap[EPO_STATUS.CLOSED] = 'Đã đóng';
// statusMap[EPO_STATUS.CANCELED] = 'Đã hủy';
statusMap[EPO_STATUS.RECORDED] = 'Chấp nhận';
statusMap['ALL'] = 'Tất cả';

export const STATUS_MAP = statusMap;

export const PO_DETAIL_TYPE = {
  CHARGEABLE: 'CHARGEABLE',
  NONE_CHARGEABLE: 'NONE_CHARGEABLE',
};

export const AUTH_CONSTANTS = {
  TOKEN: 'epo_token',
  REFRESH_TOKEN: 'epo_refresh_token',
  ROLES: 'epo_roles',
  EPO_USER_ID: 'epo_user_id',
  EPO_USERNAME: 'epo_username',
  TOKEN_EXPIRED: 'epo_token_expired',
  EPO_USER_FULLNAME: 'epo_userFullName',
  LANGUAGE: 'epo_language',
};

// export const IMPORT_EXPORT = {
//   LIST_TYPE: [
//     { name: 'Ngân sách', urlImport: API_URL.IMPORT_CSV.IMPORT_BUDGET, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_BUDGET, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_BUDGET },
//     {
//       name: 'Phòng ban tài trợ',
//       urlImport: API_URL.IMPORT_CSV.IMPORT_SPONSOR_DEPART,
//       urlExportCsv: API_URL.EXPORT_CSV.EXPORT_SPONSOR_DEPART,
//       urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_SPONSOR_DEPART,
//     },
//     {
//       name: 'Phòng ban điều khiển',
//       urlImport: API_URL.IMPORT_CSV.IMPORT_CONTROL_DEPART,
//       urlExportCsv: API_URL.EXPORT_CSV.EXPORT_CONTROL_DEPART,
//       urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_CONTROL_DEPART,
//     },
//     { name: 'Danh sách người duyệt', urlImport: API_URL.IMPORT_CSV.IMPORT_APPROVER, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_APPROVER, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_APPROVER },
//     { name: 'Khách hàng', urlImport: API_URL.IMPORT_CSV.IMPORT_CUSTOMER, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_CUSTOMER, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_CUSTOMER },
//     { name: 'Nhà cung cấp', urlImport: API_URL.IMPORT_CSV.IMPORT_SUPPLIER, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_SUPPLIER, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_SUPPLIER },
//     { name: 'Nhân viên', urlImport: API_URL.IMPORT_CSV.IMPORT_USER, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_USER, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_USER },
//     { name: 'Nhân viên OSS', urlImport: API_URL.IMPORT_CSV.IMPORT_USER_OSS, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_USER_OSS, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_USER_OSS },
//     {
//       name: 'Bộ phận kế toán',
//       urlImport: API_URL.IMPORT_CSV.IMPORT_ACCOUNTANT_DEPART,
//       urlExportCsv: API_URL.EXPORT_CSV.EXPORT_ACCOUNTANT_DEPART,
//       urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_ACCOUNTANT_DEPART,
//     },
//     { name: 'Danh mục vật tư', urlImport: API_URL.IMPORT_CSV.IMPORT_MATERIAL, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_MATERIAL, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_MATERIAL },
//     { name: 'Danh mục tiền tệ', urlImport: API_URL.IMPORT_CSV.IMPORT_CURRENCY, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_CURRENCY, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_CURRENCY },
//     { name: 'Danh mục thuế', urlImport: API_URL.IMPORT_CSV.IMPORT_TAX, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_TAX, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_TAX },
//     { name: 'Khoản mục ngân sách', urlImport: API_URL.IMPORT_CSV.IMPORT_BUDGET_ITEM, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_BUDGET_ITEM, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_BUDGET_ITEM },
//     {
//       name: 'Chi tiết khoản mục ngân sách',
//       urlImport: API_URL.IMPORT_CSV.IMPORT_BUDGET_ITEM_DETAIL,
//       urlExportCsv: API_URL.EXPORT_CSV.EXPORT_BUDGET_ITEM_DETAIL,
//       urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_BUDGET_ITEM_DETAIL,
//     },
//     { name: 'Danh mục ngân hàng', urlImport: API_URL.IMPORT_CSV.IMPORT_OSS_BANK, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_OSS_BANK, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_OSS_BANK },
//     { name: 'Danh mục loại chi phí - OSS', urlImport: API_URL.IMPORT_CSV.IMPORT_OSS_COSTS, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_OSS_COSTS, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_OSS_COSTS },
//     {
//       name: 'Danh mục bộ phận - OSS',
//       urlImport: API_URL.IMPORT_CSV.IMPORT_OSS_DEPARTMENT,
//       urlExportCsv: API_URL.EXPORT_CSV.EXPORT_OSS_DEPARTMENT,
//       urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_OSS_DEPARTMENT,
//     },
//     {
//       name: 'Danh mục đối tượng thanh toán - OSS',
//       urlImport: API_URL.IMPORT_CSV.IMPORT_OSS_PAYMENT_SUBJECT,
//       urlExportCsv: API_URL.EXPORT_CSV.EXPORT_OSS_PAYMENT_SUBJECT,
//       urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_OSS_PAYMENT_SUBJECT,
//     },
//     { name: 'Giới hạn tạm ứng', urlImport: API_URL.IMPORT_CSV.IMPORT_ADVANCE_LIMIT, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_ADVANCE_LIMIT, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_ADVANCE_LIMIT },
//     { name: 'Danh mục ngày nghỉ trong năm', urlImport: API_URL.IMPORT_CSV.IMPORT_HOLIDAY, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_HOLIDAY, urlExportExcel: null },
//     { name: 'Khoản mục chi phí', urlImport: API_URL.IMPORT_CSV.IMPORT_EXPENSE_ITEM, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_EXPENSE_ITEM, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_EXPENSE_ITEM },
//     { name: 'Địa điểm', urlImport: API_URL.IMPORT_CSV.IMPORT_LOCATION, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_LOCATION },
//     { name: 'Đơn vị', urlImport: API_URL.IMPORT_CSV.IMPORT_UNIT_OF_MEASURE, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_UNIT_OF_MEASURE },
//     {
//       name: 'Log',
//       urlExportCsv: API_URL.EXPORT_CSV.EXPORT_LOG,
//       parameters: [
//         { name: 'startTimestamp', formatFnc: (date) => date.getTime() },
//         { name: 'endTimestamp', formatFnc: (date) => date.getTime() },
//       ],
//     },
//     {
//       name: 'Log lỗi',
//       urlExportCsv: API_URL.EXPORT_CSV.EXPORT_EXCEPTION_LOG,
//       parameters: [
//         { name: 'startTimestamp', formatFnc: (date) => date.getTime() },
//         { name: 'endTimestamp', formatFnc: (date) => date.getTime() },
//       ],
//     },
//   ].sort((a, b) => a.name.localeCompare(b.name)),
// };

export const SEARCH_PARAMS_AD_CONSTANT = {
  SEARCH_TEXT: 'searchText',
  PAGE: 'page',
  SIZE: 'size',
  FROM_DATE: 'fromDate',
  TO_DATE: 'toDate',
  STATUS: 'status',
  AD_ID: 'adId',
  CREATED_BY: 'createdBy',
  BUDGET_CODE: 'budgetCode',
  AD_OSS_TYPE: 'adType',
  DESCRIPTION: 'description',
  PO_ID: 'poId',
  PO_CREATED_BY: 'poCreatedBy',
  PO_DESCRIPTION: 'poDescription',
  SORT: 'sort',
};

export const SEARCH_PARAMS_CONSTANT = {
  PAGE: 'page',
  SIZE: 'size',
  FROM_DATE: 'fromDate',
  TO_DATE: 'toDate',
  STATUS: 'status',
  EX_TYPE: 'exType',
  CREATED_BY: 'createdBy',
  EX_ID: 'exId',
  PO_ID: 'poId',
  BUDGET_CODE: 'budgetCode',
  DESCRIPTION: 'description',
  SEARCHTEXT: 'searchText',
  SUPPLIER_ID: 'supplierId',
  SEARCH_TEXT: 'searchText',
  PO_SEARCH_TEXT: 'poSearchText',
  AD_SEARCH_TEXT: 'adSearchText',
};

export const TYPE_TAX = {
  VKCT: 'VKCT', // chua thuế
  V00: 'V00', // chua thuế
  V05: 'V05', // chua thuế
  V10: 'V10', // chưa thuế
  V7: 'V07', // chưa thuế
  V3_5: 'V3.5', // chưa thuế
  V05A: 'V05A', // bao gồm thuế
  V10A: 'V10A', // bao gồm thuế
  NO_TAX: 'NO_TAX', // chưa thuế
  priceIncludeTax(taxType: string) {
    return taxType === TYPE_TAX.V05A || taxType === TYPE_TAX.V10A;
  },
};

export const TYPE_OBJECT = {
  TLN: 'TLN',
  SUPPLIER: 'SUPPLIER',
  OSS: 'OSS',
};

export const PAYMENT_TYPE_OBJECT = {
  INTERNAL: [
    { name: 'Nhân viên TLN', value: TYPE_OBJECT.TLN },
    { name: 'Nhà cung cấp', value: TYPE_OBJECT.SUPPLIER },
  ],
  OSS: [
    { name: 'Nhân viên TLN', value: TYPE_OBJECT.TLN },
    { name: 'Nhà cung cấp', value: TYPE_OBJECT.SUPPLIER },
    { name: 'Nhân viên OSS', value: TYPE_OBJECT.OSS },
  ],
};

export const PAYMENT_TYPE = [
  { name: 'Bank Transfer', value: 'TRANSFER' },
  { name: 'Cash Transfer', value: 'CASH' },
];
export const PAYMENT_TYPE_MAP = {
  TRANSFER: 'Bank Transfer',
  CASH: 'Cash Transfer',
};

export const SERVICE_FEE_TYPES = [
  { name: 'Theo công thức', value: 'FORMULAR' },
  { name: 'Không theo công thức', value: 'NO_FORMULAR' },
];
export const SERVICE_FEE_TYPES_MAP = {
  FORMULAR: 'Theo công thức',
  NO_FORMULAR: 'Không theo công thức',
};

export const PERCENT_SERVICE_FEE_TYPES = [
  { name: 'Tiền sau VAT', value: 'AFTER_TAX' },
  { name: 'Tiền trước VAT', value: 'BEFORE_TAX' },
];
export const PERCENT_SERVICE_FEE_TYPES_MAP = {
  AFTER_TAX: 'Tiền sau VAT',
  BEFORE_TAX: 'Tiền trước VAT',
};

export const CLIENT_TYPES = [
  { name: 'Nhân viên', value: 'EMPLOYEE' },
  { name: 'Nhà cung cấp', value: 'SUPPLIER' },
];

export const REVISE_TYPES = [
  { name: 'Higher Limit', value: 'HIGHER_LIMIT' },
  { name: 'Client Confirmed', value: 'CLIENT_CONFIRMED' },
];

export const COST_SHARING_TARGETS = [
  { name: 'Nhân viên', value: 'EMPLOYEE' },
  { name: 'Bộ phận', value: 'DEPARTMENT' },
];

export const PURCHASE_ORDER_TYPES = ['NONE_CHARGEABLE', 'CHARGEABLE'];

export const OSS_EXPENSE_TYPES = [
  { name: 'Gốc', value: 'ORIGINAL' },
  { name: 'Scan', value: 'SCAN' },
  { name: 'Chuyển đổi', value: 'CONVERT' },
];

export const BANK_NAME = [
  { name: 'Vietcombank', account: '007.100.4287366', desc: 'Vietcombank_007.100.4287366' },
  { name: 'HSBC', account: '001-108802-002', desc: 'HSBC_001-108802-002' },
  { name: 'Vietinbank', account: '116000179750', desc: 'Vietinbank_116000179750' },
];

export const blockUiRequestFilterOut = [
  {
    method: 'GET',
    url: /eclaim-service\/v1\/api/,
    //eclaim-service/v1/api/dgs/v2
  },
  {
    method: 'GET',
    url: /eclaim-service\/v1\/api\/dgs\/v2/,
  },
];
export const getArrayItemsAdaptVersion = ({
  result,
  mapOptions = null,
  labelKey = null,
  valueKey = null,
}: {
  result?: any;
  mapOptions?: boolean;
  labelKey?: string[] | string | any;
  valueKey?: string[] | string | any;
}) => {
  mapOptions = mapOptions || null;
  labelKey = labelKey || 'label'; // 'budgetName'
  valueKey = valueKey || 'value'; // 'id
  let items = Array.isArray(result) && result.length > 0 ? result : [];
  if (Array.isArray(result?.content) && result?.content?.length > 0) {
    items = result?.content;
  }
  if (items.length == 0) {
    if (Array.isArray(result?.data?.items) && result?.data?.items?.length > 0) {
      items = result?.data?.items;
    }
  }
  if (!mapOptions) return items;
  return items.map((r) => {
    let labelValue = ''; //concat array key
    if (Array.isArray(labelKey) && labelKey.length > 0) {
      for (let k of labelKey) {
        let hasSperateChar = !!labelValue;
        let sperateChar = `${r[k] ? ' - ' : ''}`;
        labelValue += `${hasSperateChar ? sperateChar : ''}${r[k] || ''}`;
      }
    }
    let valueValue = '';
    if (Array.isArray(valueKey) && valueKey.length > 0) {
      for (let k of valueKey) {
        if (r[k]) {
          valueValue = r[k];
          break;
        }
      }
    }
    return {
      ...r,
      label: labelValue || r[labelKey] || r.name || r.code,
      value: valueValue || r[valueKey] || r.code || r.id,
    };
  });
};

export const getApiErrorKeyMessage = (response) => {
  let message = response?.error?.message || '';
  let key = response?.error?.errorKey || '';
  if (message) {
    // neu BE tra ve message => uu tien show message.
    return {
      message,
    };
  }
  if (message == key) {
    return {
      key: response?.error?.errorKey, //need translate this key
    };
  }
  // if(message && String(message).includes('_')){ // message is not friendly message => need translate
  //   return {
  //     key,
  //   }
  // }
  // if(message){
  //   return {
  //     message,
  //   }
  // }
  return {
    message,
    key,
  };
};
