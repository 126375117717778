<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" [routerLink]="['/']">
      <!-- <span>Eclaim</span> -->
      <img class="logo-icon" src="assets/images/logo-top.png" alt="logo" />
    </a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action>
      <img class="icon-help" src="assets/icons/search-black.svg" alt="logo" />
    </nb-action>
    <nb-action>
      <img class="icon-help" src="assets/icons/help-circle-black.svg" alt="logo" />
    </nb-action>
    <nb-action>
      <img class="icon-help" src="assets/icons/bell-black-01.svg" alt="logo" />
    </nb-action>
    <nb-action class="app-lang-block">
      <ngx-app-language></ngx-app-language>
    </nb-action>
    <nb-action class="user-action">
      <span [nbContextMenu]="userMenu" nbContextMenuTag="profile-context-menu">
        <nb-user class="user-avatar-top" [name]="user?.name" [picture]="user?.picture || user?.avatar || 'assets/images/logo-circle.png'" [title]="user?.role"> </nb-user>
        <!-- <nb-user class="user-avatar-top" onlyPicture]="userPictureOnly" [name]="user?.name" [picture]="user?.picture || user?.avatar || 'assets/images/logo-circle.png'"></nb-user> -->
      </span>
    </nb-action>
  </nb-actions>
</div>
