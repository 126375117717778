import { AUTH_CONSTANTS } from '../../../@const/app.constants';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { APP_CONSTANTS } from '../../../@const/app.constants';
import { Injectable } from '@angular/core';
import { ServiceHelper } from '../helpers/service.helper';
import { ROUTE_CONTANTS } from 'app/@const/route.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateGuard implements CanActivate {
  constructor(public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (ServiceHelper.isObjectNotEmpty(localStorage.getItem(AUTH_CONSTANTS.TOKEN))) {
      return of(true);
    }
    this.router.navigate([ROUTE_CONTANTS.LOGIN]);
    return of(false);
  }
}
