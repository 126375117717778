import { Component } from '@angular/core';
import { AppLanguageService } from '../../services/app-language.service';
import { LANG_EN, LANG_VI } from '../../../../@const/app.constants';
// import { LANG_EN, LANG_VI } from '../../../../app.const';

@Component({
  selector: 'ngx-app-language',
  templateUrl: './app-language.component.html',
  styleUrls: ['./app-language.component.scss'],
})
export class AppLanguageComponent {
  languageItems = [
    {
      label: 'VI',
      value: LANG_VI,
      svg: 'assets/images/vn.svg',
    },
    {
      label: 'EN',
      value: LANG_EN,
      svg: 'assets/images/us.svg',
    },
  ];
  selectedLanguage = LANG_VI;
  // selectedLanguage: string = 'en';
  constructor(private appLanguageService: AppLanguageService) {
    this.selectedLanguage = this.appLanguageService.getLanguage() || LANG_VI;
    // console.log(this.selectedLanguage);
  }

  onLanguageSelect(language) {
    console.log(`on change langu`, language);
    this.selectedLanguage = language;
    this.appLanguageService.setLanguage(language);
    this.appLanguageService.use(language);
  }
}
