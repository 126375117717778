import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuItem } from '@nebular/theme';
import { EpoLoginService } from '../../../pages/common/services/epo-login.service';
import { AppLanguageService } from '../../../pages/common/services/app-language.service';
import { CommonDataService } from '../../../pages/common/services/common-data.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>

        <nb-sidebar-footer *ngIf="showBottomMenu">
          <nb-menu class="menu-sidebar-footer-custom" [items]="menuFooter"></nb-menu>
        </nb-sidebar-footer>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit {
  showBottomMenu = false;
  constructor(
    public router: Router,
    private epoLoginService: EpoLoginService,
    private appLanguageService: AppLanguageService,
    public commonDataService: CommonDataService
  ) {
    this.appLanguageService.use(this.appLanguageService.getLanguage());
  }
  ngOnInit(): void {
    if (this.epoLoginService.getCurrentUserId()) {
    }
  }
  menuFooter: NbMenuItem[] = [
    {
      title: 'test menu footer 1',
      icon: 'heart',
      link: '/pages/imprint/overview',
      home: false,
      children: [{ title: 'bottom menu 1', icon: 'heart', link: '/pages/imprint/overview', home: false }],
    },
    {
      title: 'test menu footer 2',
      // icon: "heart",
      link: '/pages/imprint/overview',
      home: false,
      icon: { icon: 'shield-dollar', pack: 'eclaim' },
    },

    {
      title: 'test menu footer 3',
      icon: 'heart',
      link: '/pages/imprint/overview',
      home: false,
      children: [
        { title: 'bottom menu 1', icon: 'heart', link: '/pages/imprint/overview', home: false },
        { title: 'bottom menu 2', icon: 'heart', link: '/pages/imprint/overview', home: false },
        { title: 'bottom menu 3', icon: 'heart', link: '/pages/imprint/overview', home: false },
      ],
    },
    {
      title: 'test menu footer 4',
      // icon: "heart",
      link: '/pages/imprint/overview',
      home: false,
      icon: { icon: 'shield-dollar', pack: 'eclaim' },
    },
  ];
}
