import { Component, OnInit } from '@angular/core';
import { NbAuthService } from './pages/auth/public_api';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'ngx-app',
  template: `<block-ui>
    <router-outlet></router-outlet>
  </block-ui> `,
})
export class AppComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  constructor(private authService: NbAuthService) {
    authService.registerSessionEvent();
  }

  ngOnInit(): void {}
}
